<template>
  <div>
    <home-carousel></home-carousel>
    <home-welcome></home-welcome>
    <home-history></home-history>
    <home-donate></home-donate>
    <home-contact></home-contact>
  </div>
</template>

<script>
import HomeCarousel from '@/components/hope/HomeCarousel.vue'
import HomeWelcome from '@/components/hope/HomeWelcome.vue'
import HomeHistory from '@/components/hope/HomeHistory.vue'
import HomeDonate from '@/components/hope/HomeDonate.vue'
import HomeContact from '@/components/hope/HomeContact.vue'

export default {
  components: {
    HomeCarousel,
    HomeWelcome,
    HomeHistory,
    HomeDonate,
    HomeContact
  },
  head: {
    title: function () {
      return {
        inner: 'Home'
      }
    }
  }
}
</script>

<template>
  <div class="container">
    <p>
      Hope and Angels Foundation is committed to protecting your privacy and developing technology
      that gives you the most powerful and safe online experience. This Statement of Privacy
      applies to the Hope and Angels Foundation Web site and governs data collection and usage.
      By using the Hope and Angels Foundation website, you consent to the data practices described
      in this statement.
    </p>
    <p><span class="SubHead">Collection of your Personal Information</span></p>
    <p>
      Hope and Angels Foundation collects personally identifiable information, such as your e-mail
      address, name, home or work address or telephone number. Hope and Angels Foundation also
      collects anonymous demographic information, which is not unique to you, such as
      your ZIP code, age, gender, preferences, interests and favorites.
    </p>
    <p>
      There is also information about your computer hardware and software that is automatically
      collected by Hope and Angels Foundation. This information can include: your IP address,
      browser type, domain names, access times and referring Web site addresses. This
      information is used by Hope and Angels Foundation for the operation of the service, to
      maintain quality of the service, and to provide general statistics regarding use
      of the Hope and Angels Foundation Web site.
    </p>
    <p>
      Please keep in mind that if you directly disclose personally identifiable information
      or personally sensitive data through Hope and Angels Foundation public message boards,
      this information may be collected and used by others. Note: Hope and Angels Foundation
      does not read any of your private online communications.
    </p>
    <p>
      Hope and Angels Foundation encourages you to review the privacy statements of Web sites
      you choose to link to from Hope and Angels Foundation so that you can understand how those
      Web sites collect, use and share your information. Hope and Angels Foundation is not responsible
      for the privacy statements or other content on Web sites outside of the Hope and Angels Foundation
      and Hope and Angels Foundation family of Web sites.
    </p>
    <p><span class="SubHead">Use of your Personal Information</span></p>
    <p>
      Hope and Angels Foundation collects and uses your personal information to operate the Hope and Angels Foundation
      Web site and deliver the services you have requested. Hope and Angels Foundation also uses
      your personally identifiable information to inform you of other products or services
      available from Hope and Angels Foundation and its affiliates. Hope and Angels Foundation may also
      contact you via surveys to conduct research about your opinion of current services
      or of potential new services that may be offered.
    </p>
    <p>
      Hope and Angels Foundation does not sell, rent or lease its customer lists to third parties.
      Hope and Angels Foundation may, from time to time, contact you on behalf of external business
      partners about a particular offering that may be of interest to you. In those cases,
      your unique personally identifiable information (e-mail, name, address, telephone
      number) is not transferred to the third party. In addition, Hope and Angels Foundation
      may share data with trusted partners to help us perform statistical analysis, send
      you email or postal mail, provide customer support, or arrange for deliveries. All
      such third parties are prohibited from using your personal information except to
      provide these services to Hope and Angels Foundation, and they are required to maintain
      the confidentiality of your information.
    </p>
    <p>
      Hope and Angels Foundation does not use or disclose sensitive personal information, such
      as race, religion, or political affiliations, without your explicit consent.
    </p>
    <p>
      Hope and Angels Foundation keeps track of the Web sites and pages our customers visit within
      Hope and Angels Foundation, in order to determine what Hope and Angels Foundation services are
      the most popular. This data is used to deliver customized content and advertising
      within Hope and Angels Foundation to customers whose behavior indicates that they are interested
      in a particular subject area.
    </p>
    <p>
      Hope and Angels Foundation Web sites will disclose your personal information, without notice,
      only if required to do so by law or in the good faith belief that such action is
      necessary to: (a) conform to the edicts of the law or comply with legal process
      served on Hope and Angels Foundation or the site; (b) protect and defend the rights or
      property of Hope and Angels Foundation; and, (c) act under exigent circumstances to protect
      the personal safety of users of Hope and Angels Foundation, or the public.
    </p>
    <p><span class="SubHead">Use of Cookies</span></p>
    <p>
      The Hope and Angels Foundation Web site use "cookies" to help you personalize your online
      experience. A cookie is a text file that is placed on your hard disk by a Web page
      server. Cookies cannot be used to run programs or deliver viruses to your computer.
      Cookies are uniquely assigned to you, and can only be read by a web server in the
      domain that issued the cookie to you.
    </p>
    <p>
      One of the primary purposes of cookies is to provide a convenience feature to save
      you time. The purpose of a cookie is to tell the Web server that you have returned
      to a specific page. For example, if you personalize Hope and Angels Foundation pages, or
      register with Hope and Angels Foundation site or services, a cookie helps Hope and Angels Foundation
      to recall your specific information on subsequent visits. This simplifies the process
      of recording your personal information, such as billing addresses, shipping addresses,
      and so on. When you return to the same Hope and Angels Foundation Web site, the information
      you previously provided can be retrieved, so you can easily use the Hope and Angels Foundation
      features that you customized.
    </p>
    <p>
      You have the ability to accept or decline cookies. Most Web browsers automatically
      accept cookies, but you can usually modify your browser setting to decline cookies
      if you prefer. If you choose to decline cookies, you may not be able to fully experience
      the interactive features of the Hope and Angels Foundation services or Web sites you visit.
    </p>
    <p><span class="SubHead">Security of your Personal Information</span></p>
    <p>
      Hope and Angels Foundation secures your personal information from unauthorized access,
      use or disclosure. Hope and Angels Foundation secures the personally identifiable information
      you provide on computer servers in a controlled, secure environment, protected from
      unauthorized access, use or disclosure. When personal information (such as a credit
      card number) is transmitted to other Web sites, it is protected through the use
      of encryption, such as the Secure Socket Layer (SSL) protocol.
    </p>
    <p><span class="SubHead">Changes to this Statement</span></p>
    <p>
      Hope and Angels Foundation will occasionally update this Statement of Privacy to reflect
      company and customer feedback. Hope and Angels Foundation encourages you to periodically
      review this Statement to be informed of how Hope and Angels Foundation is protecting your
      information.
    </p>
    <p><span class="SubHead">Contact Information</span></p>
    <p>
      Hope and Angels Foundation welcomes your comments regarding this Statement of Privacy.
      If you believe that Hope and Angels Foundation has not adhered to this Statement, please
      contact Hope and Angels Foundation at <a href="mailto:hopeandangels@gmail.com" style="color:#025452">hopeandangels@gmail.com</a>.
      We will use commercially reasonable efforts to promptly determine and remedy the
      problem.
    </p>
  </div>
</template>

<script>
export default {
  name: 'PrivacyContent'
}
</script>
